.Container {
  display: flex;
  flex-direction: column;
  background: #ffffff;
}

.Label {
  font-style: normal;
  font-size: 15px;
  line-height: 19px;
  text-transform: uppercase;
  color: #a9b3be;
  margin-bottom: 8px;
}

.Value {
  color: #4a4a4a;
}

.InfoBlock {
  display: grid;
  grid-template-columns: 150px 150px;
  row-gap: 27px;
  column-gap: 60px;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.InfoElement {
  display: flex;
  flex-direction: column;
}

.ImagesBlock {
  display: grid;
  gap: 21px;
  grid-template-columns: 250px 250px;
}

.ImageElement {
  display: flex;
  flex-direction: column;

  img {
    max-height: 250px;
    max-width: 30vw;
  }
}

.TopBlockWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.ImageText {
  margin-top: 8px;
}

.BottomBlockWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 23px;
}

.ActionsBlock {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 20px;
  justify-content: flex-end;
}

.NurseLabel {
  font-style: normal;
  font-size: 15px;
  line-height: 19px;
  color: #4a4a4a;
  margin-bottom: 15px;
}

.NurseBlock {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.NurseLicenseLine {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 18px;
  margin-bottom: 25px;
}

.NurseCard {
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
}

.SelectBlock {
  display: grid;
  grid-template-columns: 320px 200px;
  column-gap: 60px;
}
