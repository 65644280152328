body {
  margin: 0;
  font-family: "CircularStd-Book", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "CircularStd-Black";
  font-weight: 900;
  font-style: normal;

  src: url("./assets/fonts/CircularStd-Black.woff") format("woff"),
    url("./assets/fonts/CircularStd-Black.woff2") format("woff2");
}

@font-face {
  font-family: "CircularStd-BlackItalic";
  font-weight: 900;
  font-style: italic;

  src: url("./assets/fonts/CircularStd-BlackItalic.woff") format("woff"),
    url("./assets/fonts/CircularStd-BlackItalic.woff2") format("woff2");
}

@font-face {
  font-family: "CircularStd-Bold";
  font-weight: 700;
  font-style: normal;

  src: url("./assets/fonts/CircularStd-Bold.woff") format("woff"),
    url("./assets/fonts/CircularStd-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "CircularStd-BoldItalic";
  font-weight: 700;
  font-style: italic;

  src: url("./assets/fonts/CircularStd-BoldItalic.woff") format("woff"),
    url("./assets/fonts/CircularStd-BoldItalic.woff2") format("woff2");
}

@font-face {
  font-family: "CircularStd-Book";
  font-weight: 400;
  font-style: normal;

  src: url("./assets/fonts/CircularStd-Book.woff") format("woff"),
    url("./assets/fonts/CircularStd-Book.woff2") format("woff2");
}

@font-face {
  font-family: "CircularStd-BookItalic";
  font-weight: 400;
  font-style: italic;

  src: url("./assets/fonts/CircularStd-BookItalic.woff") format("woff"),
    url("./assets/fonts/CircularStd-BookItalic.woff2") format("woff2");
}

@font-face {
  font-family: "CircularStd-Medium";
  font-weight: 500;
  font-style: normal;

  src: url("./assets/fonts/CircularStd-Medium.woff") format("woff"),
    url("./assets/fonts/CircularStd-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "CircularStd-MediumItalic";
  font-weight: 500;
  font-style: italic;

  src: url("./assets/fonts/CircularStd-MediumItalic.woff") format("woff"),
    url("./assets/fonts/CircularStd-MediumItalic.woff2") format("woff2");
}
