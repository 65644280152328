.MainData {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 30px;
  padding: 120px 20px 20px 20px;
  background-color: #fff;
  position: relative;
  box-shadow: 0px 2px 2px #e8ecf1;
  border-radius: 3px;
  margin-top: 60px;
}

.Image {
  border-radius: 50%;
  position: absolute;
  width: 140px;
  height: 140px;
  background-color: cornflowerblue;
  top: -70px;
  left: calc(50% - 70px);
  background: center no-repeat;
  background-size: cover;
  transition: 0.5s;
}
