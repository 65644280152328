.Container {
  padding: 36px 39px;
  background-color: #f9f9fa;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: stretch;
}

.ListElement {
  padding: 0 30px;
  display: grid;
  grid-template-columns: 1fr 30px;
  height: 86px;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0px 2px 2px #e8ecf1;
  border-radius: 3px;
  margin-bottom: 18px;
  cursor: pointer;
}
