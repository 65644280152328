.sidebarNavigation {
  width: 300px;
  background-color: #fff;
  height: 100%;

  .logo {
    display: flex;
    margin-left: 1rem;
  }
}

.navigation {
  &Header {
    display: flex;
    align-items: center;
    padding: 3.125rem;
  }

  &MenuItems {
    display: flex;
    flex-direction: column;
  }

  &MenuItem {
    font-size: 15px;
    display: flex;
    align-items: center;
    padding: 0.875rem 2.25rem;
    text-decoration: none;
    border-left: 5px solid #fff;
    svg path {
      fill: #a9b3be;
    }

    &Icon {
      margin-right: 1rem;
    }

    &Text {
      color: #a9b3be;
      transition: all 0.2s ease-in-out 0s;
    }

    &Active {
      svg path {
        fill: #1963b0;
      }

      .navigationMenuItemText {
        color: #1963b0;
      }
      color: #1963b0;
      border-left: 5px solid #1963b0;
    }
  }
}
