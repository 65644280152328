.TableHeader {
  display: grid;
  align-items: center;
  padding: 0 30px;
  margin-bottom: 10px;
}

.TableLabel {
  color: #a9b3be;
  text-transform: uppercase;
}

.UserBlockView {
  padding: 0 30px;
  display: grid;
  height: 86px;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0px 2px 2px #e8ecf1;
  border-radius: 3px;
  margin-bottom: 10px;
}
